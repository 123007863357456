import React, { useEffect, useMemo, useState } from "react";

import { Badge, Space, Tag, Typography } from "antd";
import { TableViews } from "../../Tables/AntTables/AntTables";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import Button from "../../../components/uielements/button";

import { AgentShiftStatus, Shift } from "../../../redux/apis/types";
import { destroy, SERVICES, update } from "../../../redux/apis/global";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/root-reducer";
import actions from "../../../redux/planning/actions";
import Modals from "../../../components/Feedback/Modal";
import {
  generateActionLabel,
  generateConfirmShiftsData,
  timeZoneMoment,
} from "../../../redux/apis/utils";
import { isAdmin, isClient, isSubContractor } from "../../../constants";
import ActionButtonsController from "../../../form/ActionButtonsController";
import { FormProvider, useForm } from "react-hook-form";
import { getShiftStatusColor } from "../Planning/planningUtils";
import { getTagColor } from "../../admin/invoices/IncomingInvoiceList";

type Props = {
  onEditShift: (shift: Shift) => void;
  onConfirmShift?: (data: any) => void;
  onForwardShifts?: (data: any) => void;
};

const ShiftList = ({ onEditShift, onForwardShifts }: Props) => {
  const [reviewShifts, setReviewShifts] = useState<any>({});
  const [confirming, setConfirming] = useState(false);
  const { planningDetails } = useSelector((state: State) => state.Planning);
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: { shifts: planningDetails?.shifts },
  });

  const [_, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    methods.setValue("shifts", planningDetails?.shifts);
  }, [planningDetails?.shifts]);

  const pendingShift = useMemo(() => {
    return planningDetails?.shifts?.filter(
      (item) => item.subcontractor_status === "pending"
    );
  }, [planningDetails?.shifts]);

  const onConfirmShift = async (data: any) => {
    setConfirming(true);
    const resp = await update(
      `${SERVICES.PLANNING}/${planningDetails.id}/shifts/confirm`,
      null,
      generateConfirmShiftsData(reviewShifts)
    );

    if (resp) {
      dispatch(
        actions.updatePlanning(
          {
            ...planningDetails,
            shifts: data.shifts,
          },
          planningDetails.id
        )
      );
      // forceUpdate();
    }

    setReviewShifts({});
    setConfirming(false);
  };

  const onConfirmAll = (status: string) => {
    const allPendingShifts: any = planningDetails?.shifts.map((item: Shift) => {
      if (item.subcontractor_status === "pending") {
        return { ...item, subcontractor_status: status };
      }
      return item;
    });
    methods.setValue("shifts", allPendingShifts, { shouldDirty: true });

    const output: any = {};
    planningDetails?.shifts
      ?.filter((item) => item.subcontractor_status === "pending")
      .forEach((item) => {
        const { id } = item;
        output[id] = status;
      });
    setReviewShifts(output);
    forceUpdate();
  };

  const onChangeAction = (action: "accepted" | "rejected", id: number) => {
    setReviewShifts((oldShifts: any) => {
      oldShifts[id] = action;
      return oldShifts;
    });
  };

  const columns = [
    {
      title: "Start",
      render: (text: string, record: Shift) => (
        <Typography.Text>
          {timeZoneMoment(
            record.start,
            planningDetails.location?.timezone
          ).format("DD-MM-YYYY HH:mm")}
        </Typography.Text>
      ),
    },
    {
      title: "End",
      render: (text: string, record: Shift) => (
        <Typography.Text>
          {record.end === record.start
            ? "in progress"
            : timeZoneMoment(
                record.end,
                planningDetails.location?.timezone
              ).format("DD-MM-YYYY HH:mm")}
        </Typography.Text>
      ),
    },
    {
      title: "Agents",
      width: "30%",
      render: (text: string, record: Shift) => (
        <Typography.Text>
          <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
            {record.agents.map((agent, index) => (
              <span key={agent.id + agent.id}>
                <span
                  style={{
                    color: getShiftStatusColor(agent.pivot.status),
                    fontWeight: "bold",
                    marginRight: 3,
                  }}
                >
                  {`[${index + 1}]`}
                </span>
                {agent.first_name + " " + agent.last_name} (
                {agent.user.phone_number})
                {index < record.agents.length - 1 && <span>, </span>}
              </span>
            ))}
          </div>
        </Typography.Text>
      ),
    },
    {
      title: "Subcontractor",
      render: (text: string, record: Shift) => (
        <Typography.Text>
          {record.subcontractor ? record.subcontractor.name : "-"}
        </Typography.Text>
      ),
      hidden: isClient ? true : false,
    },
    {
      title: "Actions",
      fixed: "right",
      hidden: isClient ? true : false,
      render: (text: string, record: Shift, index: number) => (
        <div>
          {isAdmin && (
            <Space size="middle">
              <EditFilled
                style={{ color: "#242b50" }}
                onClick={() => onEditShift(record)}
              />
              <DeleteFilled
                style={{ color: "red" }}
                onClick={() => onDeleteShift(record.id)}
              />
              {record.extra_rate == 1 && (
                <Tag color={getTagColor("error")}>Extra</Tag>
              )}
            </Space>
          )}
          {isSubContractor && record.subcontractor_status === "pending" && (
            <Space size="middle">
              <ActionButtonsController
                onChange={(action: any) => onChangeAction(action, record.id)}
                rules={{ required: false }}
                name={`shifts.${index}.subcontractor_status`}
              />
            </Space>
          )}
          {isSubContractor && record.subcontractor_status === "confirmed" && (
            <Space size="middle">
              <EditFilled
                style={{ color: "#242b50" }}
                onClick={() => onEditShift(record)}
              />
            </Space>
          )}
        </div>
      ),
    },
  ];

  const onDeleteShift = async (id: number) => {
    Modals.confirm({
      title: "Are you sure you want to delete this shift ?",
      content: "All agents will be unassigned from this shift",
      async onOk() {
        await destroy(SERVICES.SHIFT, id);

        const copy = Object.assign({}, planningDetails);
        copy.shifts = copy.shifts.filter((item) => item.id !== id);
        dispatch(actions.updatePlanning(copy));
      },
      onCancel() {},
      okText: "Confirm",
      cancelText: "Cancel",
    });
  };

  const getRowClassName = (record: Shift) => {
    // Define your logic to determine the background color based on record data
    if (record.subcontractor_status === "pending") {
      return "pending-status"; // This class name will be applied to the row
    }
    if (
      record.subcontractor_status === "confirmed" &&
      record.agents.length === 0
    ) {
      return "confirmed-no-agents-status"; // This class name will be applied to the row
    }
    if (record.subcontractor_status === "confirmed") {
      return "confirmed-status"; // This class name will be applied to the row
    }
    if (record.subcontractor_status === "rejected") {
      return "rejected-status"; // This class name will be applied to the row
    }
    return ""; // Default class name (no additional styles)
  };

  return (
    <FormProvider {...methods}>
      {isAdmin && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {pendingShift?.length > 0 && (
              <Typography.Text type="warning">
                You have <b>{pendingShift?.length}</b> pending shift(s) ,
                waiting for subcontractor confirmation
              </Typography.Text>
            )}
          </div>
          <Button
            onClick={onForwardShifts}
            disabled={planningDetails?.shifts?.length === 0}
            style={{ bottom: 10 }}
            type="primary"
          >
            forward shift(s)
          </Button>
        </div>
      )}

      {isSubContractor && pendingShift?.length > 0 && (
        <div>
          <Typography.Text type="warning">
            You have <b>{pendingShift?.length}</b> pending shift(s) please
            accept or reject and then click on confirm
          </Typography.Text>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: 10,
              marginRight: 15,
            }}
          >
            <Button
              onClick={methods.handleSubmit(onConfirmShift)}
              loading={confirming}
              disabled={Object.keys(reviewShifts).length === 0 || confirming}
              type="primary"
            >
              {generateActionLabel(
                Object.keys(reviewShifts).map((key) => ({
                  id: key,
                  subcontractor_status: reviewShifts[key],
                })),
                "subcontractor_status"
              )}{" "}
              pending shift(s)
            </Button>
            <div style={{ display: "flex", gap: 10 }}>
              <Button
                size="small"
                type="success"
                onClick={() => onConfirmAll("confirmed")}
              >
                accept all pending
              </Button>
              <Button
                size="small"
                type="danger"
                onClick={() => onConfirmAll("rejected")}
              >
                reject all pending
              </Button>
            </div>
          </div>
        </div>
      )}

      <TableViews.SimpleView
        rowClassName={getRowClassName}
        dataSource={planningDetails?.shifts}
        columns={columns.filter((item) => !item.hidden)}
      />
      {/* {isSubContractor && (
        <Button
          style={{ marginTop: 10 }}
          onClick={methods.handleSubmit(onConfirmShift)}
          disabled={!methods.formState.isDirty}
          type="primary"
        >
          {generateActionLabel(
            methods.getValues().shifts,
            "subcontractor_status"
          )}
        </Button>
      )} */}
    </FormProvider>
  );
};

export default ShiftList;
